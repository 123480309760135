<template>
  <!-- CONTENT -->
  <div class="container">
    <div class="d-flex">
      <PageTitle>Molecular Tests</PageTitle>
      <button class="btn btn-primary ml-auto" @click="toggleModal">Add Test</button>
    </div>
    <DxTreeList
      id="molecularTestGrid"
      :data-source="dataSource"
      :show-borders="true"
      :word-wrap-enabled="true"
      data-structure="tree"
      :expand-nodes-on-filtering="true"
      :rendery-async="true"
      :allowColumnReordering="true"
      :allowColumnResizing="true"
      :row-alternation-enabled="true"
      no-data-text="No tests found"
      :columns="columns"
      ref="gridRef"
    >
      <template v-slot:actions="{ data: { data } }">
        <div class="d-flex justify-content-center">
          <icon-button icon="edit" class="btn pointer p-0 mr-2" @click="editTest(data.id)" />

          <icon-button
            icon="trash-alt"
            class="btn text-danger pointer p-0"
            @click="deleteTest(data.id)"
          />
        </div>
      </template>
    </DxTreeList>

    <Modal :status="isModalOpen" @close="toggleModal">
      <div class="container p-4">
        <h3>Add Molecular Test</h3>
        <form @submit="handleSubmit" class="form-grid">
          <SelectInput
            required
            label="Select Test Or Panel"
            :dataSource="options"
            v-model="targetId"
          />
          <div class="footer">
            <button class="btn btn-primary ml-auto" type="submit">Save</button>
          </div>
        </form>
      </div>
    </Modal>
  </div>
</template>

<script>
import { SpecimensApi } from "@/services";
import { DxTreeList } from "devextreme-vue";
import { mapState } from "vuex";
import PageTitle from "../common/PageTitle.vue";
import Modal from "../common/Modal.vue";
import SelectInput from "../common/SelectInput.vue";
import cytologyService, { CytologyEntities } from "@/services/cytology";
import { CytMolecTestStatusEnum, enumToDropDown } from "@/modules/enums";
import IconButton from "../common/IconButton.vue";

export default {
  components: { DxTreeList, PageTitle, Modal, SelectInput, IconButton },
  data() {
    return {
      isModalOpen: false,
      testOptions: cytologyService.createSearch(CytologyEntities.CytMolecTests),
      molecularPanelOptions: cytologyService.createSearch(CytologyEntities.CytMolecPanels),
      statusOptions: enumToDropDown(CytMolecTestStatusEnum),
      molecTests: {},
      targetId: null,
      testForm: {
        specimenId: null,
        cytMolecTestId: null,
        cytMolecTestStatusId: "Sent"
      },
      options: []
    };
  },
  created() {
    if (this.specimenIds?.length) {
      this.loadSpecimenTests();
    }
    this.molecularPanelOptions.load().then(data => {
      this.options = [
        ...this.options,
        ...data.map(e => ({ id: e.id, displayName: `${e.displayText} (P)`, type: "panel" }))
      ];
      return this.testOptions.load().then(data => {
        this.options = [
          ...this.options,
          ...data.map(e => ({ id: e.id, displayName: `${e.displayText} (T)`, type: "test" }))
        ];
      });
    });
  },
  computed: {
    ...mapState({
      specimens: state => state.accessionStore.caseDetails.specimens,
      user: state => state.currentUser
    }),
    columns() {
      return [
        {
          dataField: "cytMolecTest",
          caption: "Test"
        },
        { dataField: "seqNum", caption: "Seq Num" },
        { dataField: "requestedBy", caption: "Requested By" },
        { dataField: "requestedOn", caption: "Requested On", dataType: "date" },
        { dataField: "sentBy", caption: "Sent By" },
        { dataField: "sentOn", caption: "Sent On" },
        { dataField: "resultedBy", caption: "Resulted By" },
        { dataField: "resultedOn", caption: "Resulted On", dataType: "date" },
        { dataField: "cytMolecResult", caption: "Result" },
        { dataField: "cytMolecTestStatus", caption: "Status" },
        {
          caption: "Actions",
          cellTemplate: "actions"
        }
      ];
    },
    caseId() {
      return this.$route.params.caseId;
    },
    specimenIds() {
      return (this.specimens || [])?.map(specimen => specimen.id);
    },
    dataSource() {
      const keys = Object.keys(this.molecTests);
      if (keys.length) {
        return keys
          .map(key => this.molecTests[key].map(e => ({ ...e, specimenId: key })))
          .flat(Infinity);
      }
      return [];
    },
    specimenOptions() {
      return this.specimens.map(specimen => ({
        id: specimen.id,
        displayName: specimen.specimenOrder
      }));
    }
  },
  methods: {
    async deleteTest(id) {
      await SpecimensApi.deleteMolecularTest(id);
      this.loadSpecimenTests();
    },
    async editTest(id) {
      const details = await SpecimensApi.getMolecularTest(id);
      this.testForm = details;
      this.toggleModal();
    },
    getSpecimenTests(specimenId) {
      return SpecimensApi.getMolecularTests(specimenId);
    },
    async loadSpecimenTests() {
      const testsMap = {};
      await Promise.all(
        this.specimenIds.map(async id => {
          const tests = await this.getSpecimenTests(id);
          testsMap[id] = tests;
        })
      );
      this.molecTests = testsMap;
    },
    toggleModal() {
      this.isModalOpen = !this.isModalOpen;
    },
    async handleSubmit(event) {
      event.preventDefault();
      const { ...rest } = this.testForm;

      const findOption = this.options.find(e => e.id === this.targetId);
      if (!findOption) {
        window.alert("Please select a test or panel.");
        return;
      }

      const specimenId = this.specimenIds[0];
      const { type, id } = findOption;

      if (type === "test") {
        const payload = {
          ...rest,
          cytMolecTestId: id,
          specimenId,
          requestedBy: this.user.displayName,
          requestedOn: new Date()
        };
        await SpecimensApi.insertMolecularTest(specimenId, payload);
      }
      if (type === "panel") {
        const targetPanel = await cytologyService.getSingleEntity(
          CytologyEntities.CytMolecPanels,
          id
        );
        const tests = targetPanel.tests
          .sort((a, b) => a.seqNum - b.seqNum)
          .map(e => ({
            cytMolecTestId: e.cytMolecTestId,
            addedByCytMolecPanelId: id,
            specimenId,
            requestedBy: this.user.displayName,
            requestedOn: new Date()
          }));
        await Promise.all(tests.map(e => SpecimensApi.insertMolecularTest(specimenId, e)));

        // const targetSpecimen = this.specimens.find(e => e.id === specimenId);
        // const currentIcds = this.specimen.icdCodes.map(e => e.id);
      }

      this.loadSpecimenTests();
      this.toggleModal();
    }
  }
};
</script>

<style lang="scss" scoped>
.form-grid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 1rem;
  .footer {
    grid-column: span 2;
  }
}
</style>
